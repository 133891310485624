require('../scss/homepage.scss');
import initFilters from './_filters-collection';

document.addEventListener('DOMContentLoaded', () =>  {
    initFilters();

    const seeMoreCtn = document.querySelector('.see-more-items');
    if (seeMoreCtn) {
        import('../../common/js/_see-more-results').then(({initSeeMore}) => {
            initSeeMore(seeMoreCtn, true);
        });
    }
})
