import {fuzzySearch} from 'tagwalk-api-client/assets/js/common/fuzzySearch';

const $ = require('jquery');
const {Router} = require('../../../js/common/router');
require('select2');

const lang = document.documentElement.lang;
const showroom = document.documentElement.getAttribute('data-showroom');
const filterEvents = $('#events');
const filterEventType = $('#event_type');

const filterIndividuals = $('#individuals');

export default function initFilters() {
    [...document.querySelectorAll('.filters-container .filter select')]
        .map(select => select.getAttribute('id'))
        .filter(id => ['sector', 'composition', 'quantity', 'events', 'event_type', 'individuals'].indexOf(id) === -1)
        .map(id => $('#' + id))
        .forEach(customFilter => {
            customFilter.select2({
                allowClear: !customFilter.offsetParent().hasClass('multiple'),
                placeholder: customFilter.closest('.filter').attr('data-placeholder'),
                matcher: fuzzySearch
            }).on('change.select2', filterChange);
        });

    if (filterEvents.length > 0) {
        initRemoteAutocompleteAndRedirectField(filterEvents, 'event_worn_looks_list');
    }

    if (filterEventType.length > 0) {
        initOfflineAutocompleteAndRedirectField(filterEventType, 'event_type_index');
    }

    if (filterIndividuals.length > 0) {
        initRemoteAutocompleteAndRedirectField(filterIndividuals, 'individuals_worn_looks_list');
    }

    $('form.filters').on('submit', function () {
        $(this).find('select, input').each(function () {
            if (!$(this).val()) {
                $(this).prop('disabled', true);
            }
        })
    });
};

function filterChange() {
    $('form.filters').trigger('submit');
    $('.filter').find('select, input').prop('disabled', true);
}

const initRemoteAutocompleteAndRedirectField = (field, redirectRoute) => {
    field.select2({
        placeholder: field.closest('.filter').attr('data-placeholder'),
        minimumInputLength: 3,
        allowClear: true,
        ajax: {
            delay: 300,
            url: field.attr('data-path'),
            dataType: 'json',
            data: params => {
                return {
                    search: params.term
                };
            }, processResults: function (data) {
                if (data.results === undefined) {
                    data = {results: data};
                }
                const results = [];

                for (let i = 0; i < data.results.length; i++) {
                    results[i] = {
                        id: data.results[i].slug, text: data.results[i].name.toUpperCase()
                    }
                }

                return {results};
            }
        }
    }).on('select2:select', e => {
        window.location.href = Router.generate(redirectRoute + '.' + lang, {showroom, 'slug': e.target.value});
    });
}

const initOfflineAutocompleteAndRedirectField = (field, redirectRoute) => {
    field.select2({
        placeholder: field.closest('.filter').attr('data-placeholder'),
        allowClear: true,
        matcher: fuzzySearch
    }).on('select2:select', e => {
        window.location.href = Router.generate(redirectRoute + '.' + lang, {showroom, 'slug': e.target.value});
    });
}
